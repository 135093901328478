// extracted by mini-css-extract-plugin
export var mt0 = "Article-module--mt-0--3xFz3";
export var pt0 = "Article-module--pt-0--3k7B6";
export var mb0 = "Article-module--mb-0--2yA9D";
export var pb0 = "Article-module--pb-0--3p6Ux";
export var ml0 = "Article-module--ml-0--1JYIG";
export var pl0 = "Article-module--pl-0--XxsnO";
export var mr0 = "Article-module--mr-0--3_n-U";
export var pr0 = "Article-module--pr-0--3BrzK";
export var mt5 = "Article-module--mt-5--1HiH9";
export var pt5 = "Article-module--pt-5--2cjKg";
export var mb5 = "Article-module--mb-5--263Bi";
export var pb5 = "Article-module--pb-5--16e5L";
export var ml5 = "Article-module--ml-5--Y64qm";
export var pl5 = "Article-module--pl-5--2YZOK";
export var mr5 = "Article-module--mr-5--2dImp";
export var pr5 = "Article-module--pr-5--38DJQ";
export var mt10 = "Article-module--mt-10--1OjAn";
export var pt10 = "Article-module--pt-10--343Nq";
export var mb10 = "Article-module--mb-10--2oceM";
export var pb10 = "Article-module--pb-10--3uUor";
export var ml10 = "Article-module--ml-10--HJKiv";
export var pl10 = "Article-module--pl-10--3MUFR";
export var mr10 = "Article-module--mr-10--3K3EB";
export var pr10 = "Article-module--pr-10--3Q0Xl";
export var mt15 = "Article-module--mt-15--32xxl";
export var pt15 = "Article-module--pt-15--2h4sj";
export var mb15 = "Article-module--mb-15--yCNOl";
export var pb15 = "Article-module--pb-15--1g0XC";
export var ml15 = "Article-module--ml-15--mAycV";
export var pl15 = "Article-module--pl-15--1XKaR";
export var mr15 = "Article-module--mr-15--3SUed";
export var pr15 = "Article-module--pr-15--2cbSj";
export var mt20 = "Article-module--mt-20--2kgjR";
export var pt20 = "Article-module--pt-20--2hGFS";
export var mb20 = "Article-module--mb-20--10OxY";
export var pb20 = "Article-module--pb-20--2IJP-";
export var ml20 = "Article-module--ml-20--3Vmlr";
export var pl20 = "Article-module--pl-20--qKiqh";
export var mr20 = "Article-module--mr-20--1h3kY";
export var pr20 = "Article-module--pr-20--esu_q";
export var mt25 = "Article-module--mt-25--3LIgd";
export var pt25 = "Article-module--pt-25--31-5V";
export var mb25 = "Article-module--mb-25--3oYT-";
export var pb25 = "Article-module--pb-25--15toY";
export var ml25 = "Article-module--ml-25--1S5Jr";
export var pl25 = "Article-module--pl-25--a5qhS";
export var mr25 = "Article-module--mr-25--2mfQU";
export var pr25 = "Article-module--pr-25---0e-t";
export var mt30 = "Article-module--mt-30--2wj7U";
export var pt30 = "Article-module--pt-30--Ebeu5";
export var mb30 = "Article-module--mb-30--cioHs";
export var pb30 = "Article-module--pb-30--N0Fyk";
export var ml30 = "Article-module--ml-30--1y08v";
export var pl30 = "Article-module--pl-30--2uw9j";
export var mr30 = "Article-module--mr-30--Jwznc";
export var pr30 = "Article-module--pr-30--_-qyO";
export var mt35 = "Article-module--mt-35--3dZOD";
export var pt35 = "Article-module--pt-35--1p06O";
export var mb35 = "Article-module--mb-35--2Fht9";
export var pb35 = "Article-module--pb-35--1Sqie";
export var ml35 = "Article-module--ml-35--2EP-w";
export var pl35 = "Article-module--pl-35--2vlmR";
export var mr35 = "Article-module--mr-35--QANJs";
export var pr35 = "Article-module--pr-35--2_r0n";
export var mt40 = "Article-module--mt-40--2JEO_";
export var pt40 = "Article-module--pt-40--3xZCJ";
export var mb40 = "Article-module--mb-40--CDCJK";
export var pb40 = "Article-module--pb-40--nNPw5";
export var ml40 = "Article-module--ml-40--1CsGm";
export var pl40 = "Article-module--pl-40--co5as";
export var mr40 = "Article-module--mr-40--3rc9L";
export var pr40 = "Article-module--pr-40--1u08e";
export var mt45 = "Article-module--mt-45--LTdN6";
export var pt45 = "Article-module--pt-45--1nm1Q";
export var mb45 = "Article-module--mb-45--1DsCc";
export var pb45 = "Article-module--pb-45--1s2xv";
export var ml45 = "Article-module--ml-45--3z-Dn";
export var pl45 = "Article-module--pl-45--3CCjF";
export var mr45 = "Article-module--mr-45--1zNtM";
export var pr45 = "Article-module--pr-45--2mvwN";
export var mt50 = "Article-module--mt-50--2EGqq";
export var pt50 = "Article-module--pt-50--3i-ZG";
export var mb50 = "Article-module--mb-50--1rR1z";
export var pb50 = "Article-module--pb-50--2nDF3";
export var ml50 = "Article-module--ml-50--3CDN_";
export var pl50 = "Article-module--pl-50--nn0iW";
export var mr50 = "Article-module--mr-50--2qRa0";
export var pr50 = "Article-module--pr-50--GOG2C";
export var mt75 = "Article-module--mt-75--214ub";
export var pt75 = "Article-module--pt-75--3Ufx1";
export var mb75 = "Article-module--mb-75--1XI97";
export var pb75 = "Article-module--pb-75--258ra";
export var ml75 = "Article-module--ml-75--34Wed";
export var pl75 = "Article-module--pl-75---QmOn";
export var mr75 = "Article-module--mr-75--2mU-P";
export var pr75 = "Article-module--pr-75--g48s3";
export var mt100 = "Article-module--mt-100--3jULs";
export var pt100 = "Article-module--pt-100--30oXA";
export var mb100 = "Article-module--mb-100--3RPx9";
export var pb100 = "Article-module--pb-100--1Ltrd";
export var ml100 = "Article-module--ml-100--2-tD-";
export var pl100 = "Article-module--pl-100--25M9f";
export var mr100 = "Article-module--mr-100--1W3_p";
export var pr100 = "Article-module--pr-100--2I4Uw";
export var zIndex1 = "Article-module--z-index-1--33AZH";
export var zIndex2 = "Article-module--z-index-2--3ijUS";
export var zIndex3 = "Article-module--z-index-3--SSzxt";
export var zIndex4 = "Article-module--z-index-4--3Pnq5";
export var zIndex5 = "Article-module--z-index-5--10_yn";
export var zIndex6 = "Article-module--z-index-6--1al8y";
export var zIndex7 = "Article-module--z-index-7--EQKn3";
export var zIndex8 = "Article-module--z-index-8--1M0Gc";
export var zIndex9 = "Article-module--z-index-9--2RHuv";
export var zIndex10 = "Article-module--z-index-10--3gDEh";
export var zIndex20 = "Article-module--z-index-20--3a6uN";
export var zIndex30 = "Article-module--z-index-30--2auHA";
export var zIndex40 = "Article-module--z-index-40--3kinQ";
export var zIndex50 = "Article-module--z-index-50--3iqeN";
export var zIndex60 = "Article-module--z-index-60--Z2B15";
export var zIndex70 = "Article-module--z-index-70--LwS0k";
export var zIndex80 = "Article-module--z-index-80--17i2b";
export var zIndex90 = "Article-module--z-index-90--353zS";
export var zIndex100 = "Article-module--z-index-100--3ii4T";
export var link = "Article-module--link--3HcSt";
export var uppercase = "Article-module--uppercase--1vm_9";
export var more = "Article-module--more--1Gs2S";
export var button = "Article-module--button--1o97x";
export var send = "Article-module--send--1BLFM";
export var sm = "Article-module--sm--2XFgn";
export var article = "Article-module--article--27QyX";
export var xl = "Article-module--xl--1ktyw";
export var l = "Article-module--l--GThoF";
export var imgWrap = "Article-module--imgWrap--z2eES";