// extracted by mini-css-extract-plugin
export var mt0 = "PageOrnament-module--mt-0--1UT_T";
export var pt0 = "PageOrnament-module--pt-0--2Exdi";
export var mb0 = "PageOrnament-module--mb-0--1_WZa";
export var pb0 = "PageOrnament-module--pb-0--3fjh6";
export var ml0 = "PageOrnament-module--ml-0--3m_7-";
export var pl0 = "PageOrnament-module--pl-0--EJNWP";
export var mr0 = "PageOrnament-module--mr-0--2Jrmj";
export var pr0 = "PageOrnament-module--pr-0--19M8g";
export var mt5 = "PageOrnament-module--mt-5--CWHWg";
export var pt5 = "PageOrnament-module--pt-5--MJCcw";
export var mb5 = "PageOrnament-module--mb-5--25gLA";
export var pb5 = "PageOrnament-module--pb-5--ifr8z";
export var ml5 = "PageOrnament-module--ml-5--17_iw";
export var pl5 = "PageOrnament-module--pl-5--1fxFF";
export var mr5 = "PageOrnament-module--mr-5--8VUsh";
export var pr5 = "PageOrnament-module--pr-5--3M_2r";
export var mt10 = "PageOrnament-module--mt-10--1Zh01";
export var pt10 = "PageOrnament-module--pt-10--1o9Ow";
export var mb10 = "PageOrnament-module--mb-10--3KG7U";
export var pb10 = "PageOrnament-module--pb-10--1X5I3";
export var ml10 = "PageOrnament-module--ml-10--2vnct";
export var pl10 = "PageOrnament-module--pl-10--OOvyV";
export var mr10 = "PageOrnament-module--mr-10--oKrcI";
export var pr10 = "PageOrnament-module--pr-10--1jURY";
export var mt15 = "PageOrnament-module--mt-15--3OTFL";
export var pt15 = "PageOrnament-module--pt-15--1uQDO";
export var mb15 = "PageOrnament-module--mb-15--2YmL2";
export var pb15 = "PageOrnament-module--pb-15--22gbG";
export var ml15 = "PageOrnament-module--ml-15--3Ofz2";
export var pl15 = "PageOrnament-module--pl-15--1KFU8";
export var mr15 = "PageOrnament-module--mr-15--1J98H";
export var pr15 = "PageOrnament-module--pr-15--1DRHn";
export var mt20 = "PageOrnament-module--mt-20--1j4Hw";
export var pt20 = "PageOrnament-module--pt-20--3tWqy";
export var mb20 = "PageOrnament-module--mb-20--2jyOE";
export var pb20 = "PageOrnament-module--pb-20--cwdUB";
export var ml20 = "PageOrnament-module--ml-20--3ewB7";
export var pl20 = "PageOrnament-module--pl-20--1s0iy";
export var mr20 = "PageOrnament-module--mr-20--WPk6u";
export var pr20 = "PageOrnament-module--pr-20--1-pHD";
export var mt25 = "PageOrnament-module--mt-25--3-Zc4";
export var pt25 = "PageOrnament-module--pt-25--2xwPG";
export var mb25 = "PageOrnament-module--mb-25--ftWmH";
export var pb25 = "PageOrnament-module--pb-25--2E_qt";
export var ml25 = "PageOrnament-module--ml-25--3LRr_";
export var pl25 = "PageOrnament-module--pl-25--k9fz8";
export var mr25 = "PageOrnament-module--mr-25--1HpRg";
export var pr25 = "PageOrnament-module--pr-25--1tuEF";
export var mt30 = "PageOrnament-module--mt-30--1-VKo";
export var pt30 = "PageOrnament-module--pt-30--Ho6b-";
export var mb30 = "PageOrnament-module--mb-30--3fifx";
export var pb30 = "PageOrnament-module--pb-30--3FASV";
export var ml30 = "PageOrnament-module--ml-30--21dRk";
export var pl30 = "PageOrnament-module--pl-30--3PBN5";
export var mr30 = "PageOrnament-module--mr-30--3GRof";
export var pr30 = "PageOrnament-module--pr-30--1YlJg";
export var mt35 = "PageOrnament-module--mt-35--2oRxO";
export var pt35 = "PageOrnament-module--pt-35--1Q1oU";
export var mb35 = "PageOrnament-module--mb-35--3Ps_S";
export var pb35 = "PageOrnament-module--pb-35--oIWDo";
export var ml35 = "PageOrnament-module--ml-35--3RBAn";
export var pl35 = "PageOrnament-module--pl-35--3x5vj";
export var mr35 = "PageOrnament-module--mr-35--1bQsP";
export var pr35 = "PageOrnament-module--pr-35--1kUoj";
export var mt40 = "PageOrnament-module--mt-40--3ACCl";
export var pt40 = "PageOrnament-module--pt-40---EVMy";
export var mb40 = "PageOrnament-module--mb-40--CwmTy";
export var pb40 = "PageOrnament-module--pb-40--14XJL";
export var ml40 = "PageOrnament-module--ml-40--1Qyef";
export var pl40 = "PageOrnament-module--pl-40--mGFir";
export var mr40 = "PageOrnament-module--mr-40--9kzFB";
export var pr40 = "PageOrnament-module--pr-40--mj94m";
export var mt45 = "PageOrnament-module--mt-45--3SpHq";
export var pt45 = "PageOrnament-module--pt-45--2BeTm";
export var mb45 = "PageOrnament-module--mb-45--heM9T";
export var pb45 = "PageOrnament-module--pb-45--3qHU8";
export var ml45 = "PageOrnament-module--ml-45--3Gjhu";
export var pl45 = "PageOrnament-module--pl-45--3Hv4c";
export var mr45 = "PageOrnament-module--mr-45--6F4IW";
export var pr45 = "PageOrnament-module--pr-45--YVogp";
export var mt50 = "PageOrnament-module--mt-50--2TnGS";
export var pt50 = "PageOrnament-module--pt-50--2F31K";
export var mb50 = "PageOrnament-module--mb-50--31ve1";
export var pb50 = "PageOrnament-module--pb-50--2_oMx";
export var ml50 = "PageOrnament-module--ml-50--3pMIg";
export var pl50 = "PageOrnament-module--pl-50--3dRhY";
export var mr50 = "PageOrnament-module--mr-50--1Mx4G";
export var pr50 = "PageOrnament-module--pr-50--XT1d8";
export var mt75 = "PageOrnament-module--mt-75--2dqOs";
export var pt75 = "PageOrnament-module--pt-75--JUYTK";
export var mb75 = "PageOrnament-module--mb-75--109Xz";
export var pb75 = "PageOrnament-module--pb-75--vfQIM";
export var ml75 = "PageOrnament-module--ml-75--3pwe8";
export var pl75 = "PageOrnament-module--pl-75--VuF72";
export var mr75 = "PageOrnament-module--mr-75--Jonmi";
export var pr75 = "PageOrnament-module--pr-75--3e-Sa";
export var mt100 = "PageOrnament-module--mt-100--1gn4l";
export var pt100 = "PageOrnament-module--pt-100--3AYdB";
export var mb100 = "PageOrnament-module--mb-100--3IPxw";
export var pb100 = "PageOrnament-module--pb-100--1nY6q";
export var ml100 = "PageOrnament-module--ml-100--1zGx0";
export var pl100 = "PageOrnament-module--pl-100--3nGQd";
export var mr100 = "PageOrnament-module--mr-100--3YTsa";
export var pr100 = "PageOrnament-module--pr-100--DLYH8";
export var zIndex1 = "PageOrnament-module--z-index-1--3Dgqd";
export var zIndex2 = "PageOrnament-module--z-index-2--1x2D2";
export var zIndex3 = "PageOrnament-module--z-index-3--1RKEk";
export var zIndex4 = "PageOrnament-module--z-index-4--2_RBd";
export var zIndex5 = "PageOrnament-module--z-index-5--3cDTl";
export var zIndex6 = "PageOrnament-module--z-index-6--1aTr5";
export var zIndex7 = "PageOrnament-module--z-index-7--2eVRY";
export var zIndex8 = "PageOrnament-module--z-index-8--_DkFW";
export var zIndex9 = "PageOrnament-module--z-index-9--10LmP";
export var zIndex10 = "PageOrnament-module--z-index-10--2jVWz";
export var zIndex20 = "PageOrnament-module--z-index-20--2vwXQ";
export var zIndex30 = "PageOrnament-module--z-index-30--1Q9Y9";
export var zIndex40 = "PageOrnament-module--z-index-40--lBiT7";
export var zIndex50 = "PageOrnament-module--z-index-50--ECUbK";
export var zIndex60 = "PageOrnament-module--z-index-60--3nmTE";
export var zIndex70 = "PageOrnament-module--z-index-70--T3PNd";
export var zIndex80 = "PageOrnament-module--z-index-80--2GyuV";
export var zIndex90 = "PageOrnament-module--z-index-90--2Hesn";
export var zIndex100 = "PageOrnament-module--z-index-100--1DlQq";
export var link = "PageOrnament-module--link--iBe7T";
export var uppercase = "PageOrnament-module--uppercase--1-KGU";
export var more = "PageOrnament-module--more--2vPC6";
export var button = "PageOrnament-module--button--1tUBx";
export var send = "PageOrnament-module--send--3gxRy";
export var sm = "PageOrnament-module--sm--39c2P";
export var pageOrnament = "PageOrnament-module--pageOrnament--_gjZi";