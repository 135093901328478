// extracted by mini-css-extract-plugin
export var mt0 = "Navbar-module--mt-0--7LtF4";
export var pt0 = "Navbar-module--pt-0--2ylIg";
export var mb0 = "Navbar-module--mb-0--1TMHG";
export var pb0 = "Navbar-module--pb-0--3d-7N";
export var ml0 = "Navbar-module--ml-0--3nopq";
export var pl0 = "Navbar-module--pl-0--2QN_j";
export var mr0 = "Navbar-module--mr-0--11WY_";
export var pr0 = "Navbar-module--pr-0--2vBg9";
export var mt5 = "Navbar-module--mt-5--33K_O";
export var pt5 = "Navbar-module--pt-5--3pgj8";
export var mb5 = "Navbar-module--mb-5--1W17S";
export var pb5 = "Navbar-module--pb-5--27tjQ";
export var ml5 = "Navbar-module--ml-5--27X6g";
export var pl5 = "Navbar-module--pl-5--2jBXi";
export var mr5 = "Navbar-module--mr-5--2O7ez";
export var pr5 = "Navbar-module--pr-5--2nzLS";
export var mt10 = "Navbar-module--mt-10--2W9Xu";
export var pt10 = "Navbar-module--pt-10--ozf99";
export var mb10 = "Navbar-module--mb-10--1TxcC";
export var pb10 = "Navbar-module--pb-10--3lWzH";
export var ml10 = "Navbar-module--ml-10--3VIcn";
export var pl10 = "Navbar-module--pl-10--1nRuf";
export var mr10 = "Navbar-module--mr-10--7A7sF";
export var pr10 = "Navbar-module--pr-10--3_nG8";
export var mt15 = "Navbar-module--mt-15--2_07j";
export var pt15 = "Navbar-module--pt-15--2_xcm";
export var mb15 = "Navbar-module--mb-15--3ZUIq";
export var pb15 = "Navbar-module--pb-15--XUE8i";
export var ml15 = "Navbar-module--ml-15--3KQ_R";
export var pl15 = "Navbar-module--pl-15--BG0zl";
export var mr15 = "Navbar-module--mr-15--26x3_";
export var pr15 = "Navbar-module--pr-15--DcrxC";
export var mt20 = "Navbar-module--mt-20--2Winy";
export var pt20 = "Navbar-module--pt-20--2NYWK";
export var mb20 = "Navbar-module--mb-20--Uxy8A";
export var pb20 = "Navbar-module--pb-20--3JR9l";
export var ml20 = "Navbar-module--ml-20--2nhmQ";
export var pl20 = "Navbar-module--pl-20--2TGhi";
export var mr20 = "Navbar-module--mr-20--1CZGn";
export var pr20 = "Navbar-module--pr-20--3ZzL_";
export var mt25 = "Navbar-module--mt-25--zk3A4";
export var pt25 = "Navbar-module--pt-25--3S5Mu";
export var mb25 = "Navbar-module--mb-25--1xmdb";
export var pb25 = "Navbar-module--pb-25--2tLUh";
export var ml25 = "Navbar-module--ml-25--seiJX";
export var pl25 = "Navbar-module--pl-25--2wKc4";
export var mr25 = "Navbar-module--mr-25--oO7kI";
export var pr25 = "Navbar-module--pr-25--1CyaS";
export var mt30 = "Navbar-module--mt-30--1z72W";
export var pt30 = "Navbar-module--pt-30--3NgwY";
export var mb30 = "Navbar-module--mb-30--30FQs";
export var pb30 = "Navbar-module--pb-30--3d8vb";
export var ml30 = "Navbar-module--ml-30--S4mx2";
export var pl30 = "Navbar-module--pl-30--2a3_U";
export var mr30 = "Navbar-module--mr-30--3XPoW";
export var pr30 = "Navbar-module--pr-30--2BFv9";
export var mt35 = "Navbar-module--mt-35--2xCI2";
export var pt35 = "Navbar-module--pt-35--3LEWW";
export var mb35 = "Navbar-module--mb-35--3BhOa";
export var pb35 = "Navbar-module--pb-35--ru3RY";
export var ml35 = "Navbar-module--ml-35--2SHyk";
export var pl35 = "Navbar-module--pl-35--2WHq3";
export var mr35 = "Navbar-module--mr-35--2pzf7";
export var pr35 = "Navbar-module--pr-35--2NoV6";
export var mt40 = "Navbar-module--mt-40--3bZki";
export var pt40 = "Navbar-module--pt-40--3Onwm";
export var mb40 = "Navbar-module--mb-40--2v45X";
export var pb40 = "Navbar-module--pb-40--1DqHc";
export var ml40 = "Navbar-module--ml-40--1AGg-";
export var pl40 = "Navbar-module--pl-40--1n5qO";
export var mr40 = "Navbar-module--mr-40--2XbPD";
export var pr40 = "Navbar-module--pr-40--300IY";
export var mt45 = "Navbar-module--mt-45--927Pc";
export var pt45 = "Navbar-module--pt-45--2HK6c";
export var mb45 = "Navbar-module--mb-45--1NPKr";
export var pb45 = "Navbar-module--pb-45--gE1gr";
export var ml45 = "Navbar-module--ml-45--1AiY5";
export var pl45 = "Navbar-module--pl-45--2zJTr";
export var mr45 = "Navbar-module--mr-45--1xMR2";
export var pr45 = "Navbar-module--pr-45--oWJEg";
export var mt50 = "Navbar-module--mt-50--3E7ea";
export var pt50 = "Navbar-module--pt-50--1czlr";
export var mb50 = "Navbar-module--mb-50--1v6mp";
export var pb50 = "Navbar-module--pb-50--1T_Me";
export var ml50 = "Navbar-module--ml-50--3DJAZ";
export var pl50 = "Navbar-module--pl-50--2Cfn0";
export var mr50 = "Navbar-module--mr-50--2c_Uc";
export var pr50 = "Navbar-module--pr-50--2Y4jc";
export var mt75 = "Navbar-module--mt-75--1H7Pj";
export var pt75 = "Navbar-module--pt-75--1mRug";
export var mb75 = "Navbar-module--mb-75--1PFWh";
export var pb75 = "Navbar-module--pb-75--XsRuh";
export var ml75 = "Navbar-module--ml-75--1olrW";
export var pl75 = "Navbar-module--pl-75--3m7LO";
export var mr75 = "Navbar-module--mr-75--VKb-l";
export var pr75 = "Navbar-module--pr-75--1_X-B";
export var mt100 = "Navbar-module--mt-100--2TfEh";
export var pt100 = "Navbar-module--pt-100--2zKRG";
export var mb100 = "Navbar-module--mb-100--2o_z1";
export var pb100 = "Navbar-module--pb-100--DqnBx";
export var ml100 = "Navbar-module--ml-100--oCG_a";
export var pl100 = "Navbar-module--pl-100--2F46K";
export var mr100 = "Navbar-module--mr-100--1U0D9";
export var pr100 = "Navbar-module--pr-100--2k2pe";
export var zIndex1 = "Navbar-module--z-index-1--3s2kt";
export var zIndex2 = "Navbar-module--z-index-2--10cXA";
export var zIndex3 = "Navbar-module--z-index-3--1WYSi";
export var zIndex4 = "Navbar-module--z-index-4--33EoD";
export var zIndex5 = "Navbar-module--z-index-5--3hP_-";
export var zIndex6 = "Navbar-module--z-index-6--kgCuo";
export var zIndex7 = "Navbar-module--z-index-7--1scDL";
export var zIndex8 = "Navbar-module--z-index-8--1Zmrm";
export var zIndex9 = "Navbar-module--z-index-9--1EXq2";
export var zIndex10 = "Navbar-module--z-index-10--3DnU9";
export var zIndex20 = "Navbar-module--z-index-20--2WGYT";
export var zIndex30 = "Navbar-module--z-index-30--Hw42x";
export var zIndex40 = "Navbar-module--z-index-40--2s94L";
export var zIndex50 = "Navbar-module--z-index-50--3ZO_c";
export var zIndex60 = "Navbar-module--z-index-60--PBXlE";
export var zIndex70 = "Navbar-module--z-index-70--37BO8";
export var zIndex80 = "Navbar-module--z-index-80--1Inez";
export var zIndex90 = "Navbar-module--z-index-90--3sRBA";
export var zIndex100 = "Navbar-module--z-index-100--uLC2v";
export var link = "Navbar-module--link--1MgIG";
export var uppercase = "Navbar-module--uppercase--b2xna";
export var more = "Navbar-module--more--XiV0G";
export var button = "Navbar-module--button--3n0pt";
export var send = "Navbar-module--send--2xt3S";
export var sm = "Navbar-module--sm--3F4j6";
export var navbar = "Navbar-module--navbar--gLzFC";
export var active = "Navbar-module--active--1COmY";
export var hide = "Navbar-module--hide--1a6pr";
export var logoWrap = "Navbar-module--logoWrap--xF4LU";
export var logo = "Navbar-module--logo--1qheg";
export var main = "Navbar-module--main--2YO-S";
export var phone = "Navbar-module--phone--3MOmx";
export var burger = "Navbar-module--burger--2UrcS";
export var mainMenu = "Navbar-module--mainMenu--1Zx20";
export var menuItem = "Navbar-module--menuItem--wIg3-";
export var menuPhone = "Navbar-module--menuPhone--18Pg0";
export var desktopLang = "Navbar-module--desktopLang--VoTX6";
export var burgerBar = "Navbar-module--burgerBar--2rDgU";