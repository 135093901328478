// extracted by mini-css-extract-plugin
export var mt0 = "LanguageSwitcher-module--mt-0--1qQZJ";
export var pt0 = "LanguageSwitcher-module--pt-0--3eW5J";
export var mb0 = "LanguageSwitcher-module--mb-0--3QwxW";
export var pb0 = "LanguageSwitcher-module--pb-0--3t4_F";
export var ml0 = "LanguageSwitcher-module--ml-0--2PM0c";
export var pl0 = "LanguageSwitcher-module--pl-0--pacK7";
export var mr0 = "LanguageSwitcher-module--mr-0--2AVjM";
export var pr0 = "LanguageSwitcher-module--pr-0--d0Q-B";
export var mt5 = "LanguageSwitcher-module--mt-5--3fzlk";
export var pt5 = "LanguageSwitcher-module--pt-5--1H4rL";
export var mb5 = "LanguageSwitcher-module--mb-5--3-E0F";
export var pb5 = "LanguageSwitcher-module--pb-5--3v2Z4";
export var ml5 = "LanguageSwitcher-module--ml-5--3RYFp";
export var pl5 = "LanguageSwitcher-module--pl-5--3jpcU";
export var mr5 = "LanguageSwitcher-module--mr-5--23Uik";
export var pr5 = "LanguageSwitcher-module--pr-5--3wulL";
export var mt10 = "LanguageSwitcher-module--mt-10--376vS";
export var pt10 = "LanguageSwitcher-module--pt-10--1eA2V";
export var mb10 = "LanguageSwitcher-module--mb-10--1ZtGK";
export var pb10 = "LanguageSwitcher-module--pb-10--32GS4";
export var ml10 = "LanguageSwitcher-module--ml-10--1k_OC";
export var pl10 = "LanguageSwitcher-module--pl-10--2d6LZ";
export var mr10 = "LanguageSwitcher-module--mr-10--3PTZS";
export var pr10 = "LanguageSwitcher-module--pr-10--30JDM";
export var mt15 = "LanguageSwitcher-module--mt-15--2E81y";
export var pt15 = "LanguageSwitcher-module--pt-15--1R46w";
export var mb15 = "LanguageSwitcher-module--mb-15--w66CF";
export var pb15 = "LanguageSwitcher-module--pb-15--3dIem";
export var ml15 = "LanguageSwitcher-module--ml-15--3PwgR";
export var pl15 = "LanguageSwitcher-module--pl-15--1lReN";
export var mr15 = "LanguageSwitcher-module--mr-15--38nfL";
export var pr15 = "LanguageSwitcher-module--pr-15--2k-ox";
export var mt20 = "LanguageSwitcher-module--mt-20--2KCc9";
export var pt20 = "LanguageSwitcher-module--pt-20--1SO2H";
export var mb20 = "LanguageSwitcher-module--mb-20--2M5if";
export var pb20 = "LanguageSwitcher-module--pb-20--2o4ym";
export var ml20 = "LanguageSwitcher-module--ml-20--Tq6bi";
export var pl20 = "LanguageSwitcher-module--pl-20--2zQgq";
export var mr20 = "LanguageSwitcher-module--mr-20--1uXQG";
export var pr20 = "LanguageSwitcher-module--pr-20--9QDEJ";
export var mt25 = "LanguageSwitcher-module--mt-25--1GDqM";
export var pt25 = "LanguageSwitcher-module--pt-25--2N5xy";
export var mb25 = "LanguageSwitcher-module--mb-25--2x0wJ";
export var pb25 = "LanguageSwitcher-module--pb-25--gl-Zc";
export var ml25 = "LanguageSwitcher-module--ml-25--G5W-U";
export var pl25 = "LanguageSwitcher-module--pl-25--3IWd5";
export var mr25 = "LanguageSwitcher-module--mr-25--2qz3x";
export var pr25 = "LanguageSwitcher-module--pr-25--1dokm";
export var mt30 = "LanguageSwitcher-module--mt-30---hLMt";
export var pt30 = "LanguageSwitcher-module--pt-30--jC2ia";
export var mb30 = "LanguageSwitcher-module--mb-30--eB6oN";
export var pb30 = "LanguageSwitcher-module--pb-30--3Dnzo";
export var ml30 = "LanguageSwitcher-module--ml-30--2-LQ2";
export var pl30 = "LanguageSwitcher-module--pl-30--mXYTU";
export var mr30 = "LanguageSwitcher-module--mr-30--HusXl";
export var pr30 = "LanguageSwitcher-module--pr-30--3BLCa";
export var mt35 = "LanguageSwitcher-module--mt-35--2u4Ao";
export var pt35 = "LanguageSwitcher-module--pt-35--14gZP";
export var mb35 = "LanguageSwitcher-module--mb-35--2NAzk";
export var pb35 = "LanguageSwitcher-module--pb-35--19UtI";
export var ml35 = "LanguageSwitcher-module--ml-35--2zDnG";
export var pl35 = "LanguageSwitcher-module--pl-35--1NRAx";
export var mr35 = "LanguageSwitcher-module--mr-35--136Px";
export var pr35 = "LanguageSwitcher-module--pr-35--EYLjd";
export var mt40 = "LanguageSwitcher-module--mt-40--iiUOf";
export var pt40 = "LanguageSwitcher-module--pt-40--20e66";
export var mb40 = "LanguageSwitcher-module--mb-40--20EDO";
export var pb40 = "LanguageSwitcher-module--pb-40--3bUb3";
export var ml40 = "LanguageSwitcher-module--ml-40--1iAZn";
export var pl40 = "LanguageSwitcher-module--pl-40--NHZBD";
export var mr40 = "LanguageSwitcher-module--mr-40--2uWbU";
export var pr40 = "LanguageSwitcher-module--pr-40--XdRVa";
export var mt45 = "LanguageSwitcher-module--mt-45--22P_9";
export var pt45 = "LanguageSwitcher-module--pt-45--3bM3w";
export var mb45 = "LanguageSwitcher-module--mb-45--1vTJo";
export var pb45 = "LanguageSwitcher-module--pb-45--2B1-X";
export var ml45 = "LanguageSwitcher-module--ml-45--AInXg";
export var pl45 = "LanguageSwitcher-module--pl-45--Q3UM9";
export var mr45 = "LanguageSwitcher-module--mr-45--3lDMA";
export var pr45 = "LanguageSwitcher-module--pr-45--KJmtK";
export var mt50 = "LanguageSwitcher-module--mt-50--314Cf";
export var pt50 = "LanguageSwitcher-module--pt-50--38lNr";
export var mb50 = "LanguageSwitcher-module--mb-50--bCULx";
export var pb50 = "LanguageSwitcher-module--pb-50--2v8x_";
export var ml50 = "LanguageSwitcher-module--ml-50--3bw-r";
export var pl50 = "LanguageSwitcher-module--pl-50--P4m4T";
export var mr50 = "LanguageSwitcher-module--mr-50--IWZQQ";
export var pr50 = "LanguageSwitcher-module--pr-50--3JMKI";
export var mt75 = "LanguageSwitcher-module--mt-75--3u-qg";
export var pt75 = "LanguageSwitcher-module--pt-75--kiCan";
export var mb75 = "LanguageSwitcher-module--mb-75--OC0O_";
export var pb75 = "LanguageSwitcher-module--pb-75--3x1Bs";
export var ml75 = "LanguageSwitcher-module--ml-75--3RR_q";
export var pl75 = "LanguageSwitcher-module--pl-75--21Gnu";
export var mr75 = "LanguageSwitcher-module--mr-75--jHN-K";
export var pr75 = "LanguageSwitcher-module--pr-75--3ftro";
export var mt100 = "LanguageSwitcher-module--mt-100--3yhl2";
export var pt100 = "LanguageSwitcher-module--pt-100--12l9g";
export var mb100 = "LanguageSwitcher-module--mb-100--2u67v";
export var pb100 = "LanguageSwitcher-module--pb-100--2dbOK";
export var ml100 = "LanguageSwitcher-module--ml-100--mA70T";
export var pl100 = "LanguageSwitcher-module--pl-100--3aXzA";
export var mr100 = "LanguageSwitcher-module--mr-100--2bf9E";
export var pr100 = "LanguageSwitcher-module--pr-100--201c0";
export var zIndex1 = "LanguageSwitcher-module--z-index-1--1sWHP";
export var zIndex2 = "LanguageSwitcher-module--z-index-2--1HcM9";
export var zIndex3 = "LanguageSwitcher-module--z-index-3--1bKiv";
export var zIndex4 = "LanguageSwitcher-module--z-index-4--2NZq5";
export var zIndex5 = "LanguageSwitcher-module--z-index-5--3fN3M";
export var zIndex6 = "LanguageSwitcher-module--z-index-6--SFDpt";
export var zIndex7 = "LanguageSwitcher-module--z-index-7--15rf8";
export var zIndex8 = "LanguageSwitcher-module--z-index-8--3AwNG";
export var zIndex9 = "LanguageSwitcher-module--z-index-9--sFnx9";
export var zIndex10 = "LanguageSwitcher-module--z-index-10--2L22P";
export var zIndex20 = "LanguageSwitcher-module--z-index-20--fLfkE";
export var zIndex30 = "LanguageSwitcher-module--z-index-30--3oHcz";
export var zIndex40 = "LanguageSwitcher-module--z-index-40--3Rc09";
export var zIndex50 = "LanguageSwitcher-module--z-index-50--1sZtx";
export var zIndex60 = "LanguageSwitcher-module--z-index-60--1CSU9";
export var zIndex70 = "LanguageSwitcher-module--z-index-70--3k6ZP";
export var zIndex80 = "LanguageSwitcher-module--z-index-80--pMqsc";
export var zIndex90 = "LanguageSwitcher-module--z-index-90--sa6qh";
export var zIndex100 = "LanguageSwitcher-module--z-index-100--Nt8x_";
export var link = "LanguageSwitcher-module--link--lN7cq";
export var uppercase = "LanguageSwitcher-module--uppercase--3Evzk";
export var more = "LanguageSwitcher-module--more--3XhtM";
export var button = "LanguageSwitcher-module--button--3ZLHo";
export var send = "LanguageSwitcher-module--send--3ep93";
export var sm = "LanguageSwitcher-module--sm--2rLST";
export var languageSwitcherWrap = "LanguageSwitcher-module--languageSwitcherWrap--1nrqM";
export var item = "LanguageSwitcher-module--item--1X78h";
export var active = "LanguageSwitcher-module--active--2qIoL";