// extracted by mini-css-extract-plugin
export var mt0 = "Footer-module--mt-0--1qlKI";
export var pt0 = "Footer-module--pt-0--Hw4Bm";
export var mb0 = "Footer-module--mb-0--2b0Nd";
export var pb0 = "Footer-module--pb-0--37m1E";
export var ml0 = "Footer-module--ml-0--24ycr";
export var pl0 = "Footer-module--pl-0--2tNZd";
export var mr0 = "Footer-module--mr-0--1V2Du";
export var pr0 = "Footer-module--pr-0--2bgkF";
export var mt5 = "Footer-module--mt-5--2ecS9";
export var pt5 = "Footer-module--pt-5--hSaGK";
export var mb5 = "Footer-module--mb-5--2kSae";
export var pb5 = "Footer-module--pb-5--28618";
export var ml5 = "Footer-module--ml-5--1zS0m";
export var pl5 = "Footer-module--pl-5--2Ef5z";
export var mr5 = "Footer-module--mr-5--2IBnN";
export var pr5 = "Footer-module--pr-5--1f0Z1";
export var mt10 = "Footer-module--mt-10--2ebDt";
export var pt10 = "Footer-module--pt-10--mO_8z";
export var mb10 = "Footer-module--mb-10--2uLQ2";
export var pb10 = "Footer-module--pb-10--2tO0O";
export var ml10 = "Footer-module--ml-10--2Q5Ee";
export var pl10 = "Footer-module--pl-10--8Vt03";
export var mr10 = "Footer-module--mr-10--15_a3";
export var pr10 = "Footer-module--pr-10--8-b2Z";
export var mt15 = "Footer-module--mt-15--F8qxP";
export var pt15 = "Footer-module--pt-15--2k-U6";
export var mb15 = "Footer-module--mb-15--jWs5F";
export var pb15 = "Footer-module--pb-15--bXLX7";
export var ml15 = "Footer-module--ml-15--qef-A";
export var pl15 = "Footer-module--pl-15--2NstP";
export var mr15 = "Footer-module--mr-15--1CEc-";
export var pr15 = "Footer-module--pr-15--2kcL4";
export var mt20 = "Footer-module--mt-20--1HyLp";
export var pt20 = "Footer-module--pt-20--XVb3c";
export var mb20 = "Footer-module--mb-20--pH8cR";
export var pb20 = "Footer-module--pb-20--1qL3M";
export var ml20 = "Footer-module--ml-20--1uKlk";
export var pl20 = "Footer-module--pl-20--1jCJS";
export var mr20 = "Footer-module--mr-20--aFbWV";
export var pr20 = "Footer-module--pr-20--31H94";
export var mt25 = "Footer-module--mt-25--2aKQ4";
export var pt25 = "Footer-module--pt-25--Mn3eZ";
export var mb25 = "Footer-module--mb-25--3RWNM";
export var pb25 = "Footer-module--pb-25--jmmDE";
export var ml25 = "Footer-module--ml-25--NpYH9";
export var pl25 = "Footer-module--pl-25--2cCD1";
export var mr25 = "Footer-module--mr-25--2o3Z8";
export var pr25 = "Footer-module--pr-25--1Qmfk";
export var mt30 = "Footer-module--mt-30--1k3MW";
export var pt30 = "Footer-module--pt-30--3u2TI";
export var mb30 = "Footer-module--mb-30---eR8T";
export var pb30 = "Footer-module--pb-30--3Wf3F";
export var ml30 = "Footer-module--ml-30--sj27O";
export var pl30 = "Footer-module--pl-30--14NsJ";
export var mr30 = "Footer-module--mr-30--M7vDM";
export var pr30 = "Footer-module--pr-30--2w0mt";
export var mt35 = "Footer-module--mt-35--1KkDI";
export var pt35 = "Footer-module--pt-35--3PP4w";
export var mb35 = "Footer-module--mb-35--39tKy";
export var pb35 = "Footer-module--pb-35--3P_6S";
export var ml35 = "Footer-module--ml-35--2cG4D";
export var pl35 = "Footer-module--pl-35--29IVf";
export var mr35 = "Footer-module--mr-35--2BMbq";
export var pr35 = "Footer-module--pr-35--31YDi";
export var mt40 = "Footer-module--mt-40--mZ2IL";
export var pt40 = "Footer-module--pt-40--2UDaC";
export var mb40 = "Footer-module--mb-40--1b8Nk";
export var pb40 = "Footer-module--pb-40--1H54S";
export var ml40 = "Footer-module--ml-40--2z_B7";
export var pl40 = "Footer-module--pl-40--1Io3p";
export var mr40 = "Footer-module--mr-40--20Q_Y";
export var pr40 = "Footer-module--pr-40--11Zyt";
export var mt45 = "Footer-module--mt-45--2R3QF";
export var pt45 = "Footer-module--pt-45--uNsrg";
export var mb45 = "Footer-module--mb-45--11Myl";
export var pb45 = "Footer-module--pb-45--QcXOY";
export var ml45 = "Footer-module--ml-45--L4hd2";
export var pl45 = "Footer-module--pl-45--Hwx42";
export var mr45 = "Footer-module--mr-45--3OYDs";
export var pr45 = "Footer-module--pr-45--3JB07";
export var mt50 = "Footer-module--mt-50--2LOMh";
export var pt50 = "Footer-module--pt-50--1e_ur";
export var mb50 = "Footer-module--mb-50--3B1TK";
export var pb50 = "Footer-module--pb-50--MYdkx";
export var ml50 = "Footer-module--ml-50--1jTGB";
export var pl50 = "Footer-module--pl-50--24PAV";
export var mr50 = "Footer-module--mr-50--IKGAg";
export var pr50 = "Footer-module--pr-50--Sg-9d";
export var mt75 = "Footer-module--mt-75--3mZgE";
export var pt75 = "Footer-module--pt-75--3cFwx";
export var mb75 = "Footer-module--mb-75--10dUN";
export var pb75 = "Footer-module--pb-75--pUWzr";
export var ml75 = "Footer-module--ml-75--3toa8";
export var pl75 = "Footer-module--pl-75--3MEU9";
export var mr75 = "Footer-module--mr-75--34Pyq";
export var pr75 = "Footer-module--pr-75--2S3M0";
export var mt100 = "Footer-module--mt-100--StKWO";
export var pt100 = "Footer-module--pt-100--3qbIs";
export var mb100 = "Footer-module--mb-100--2buHl";
export var pb100 = "Footer-module--pb-100--235EQ";
export var ml100 = "Footer-module--ml-100--2bTXC";
export var pl100 = "Footer-module--pl-100--211D_";
export var mr100 = "Footer-module--mr-100--3FXiQ";
export var pr100 = "Footer-module--pr-100--3BKJm";
export var zIndex1 = "Footer-module--z-index-1--2K28H";
export var zIndex2 = "Footer-module--z-index-2--2_b7H";
export var zIndex3 = "Footer-module--z-index-3--2h9sQ";
export var zIndex4 = "Footer-module--z-index-4--1E8BE";
export var zIndex5 = "Footer-module--z-index-5--3dgki";
export var zIndex6 = "Footer-module--z-index-6--2cPwg";
export var zIndex7 = "Footer-module--z-index-7--2jqc-";
export var zIndex8 = "Footer-module--z-index-8--3OTft";
export var zIndex9 = "Footer-module--z-index-9--1JfBi";
export var zIndex10 = "Footer-module--z-index-10--3wI0C";
export var zIndex20 = "Footer-module--z-index-20--3BT1T";
export var zIndex30 = "Footer-module--z-index-30--CUxUq";
export var zIndex40 = "Footer-module--z-index-40--tNd-m";
export var zIndex50 = "Footer-module--z-index-50--3YT0N";
export var zIndex60 = "Footer-module--z-index-60--_xpCg";
export var zIndex70 = "Footer-module--z-index-70--3XEgd";
export var zIndex80 = "Footer-module--z-index-80--3w5gR";
export var zIndex90 = "Footer-module--z-index-90--1s4_3";
export var zIndex100 = "Footer-module--z-index-100--3aIAI";
export var link = "Footer-module--link--30djJ";
export var uppercase = "Footer-module--uppercase--3MjBi";
export var more = "Footer-module--more--1mgqY";
export var button = "Footer-module--button--3S86N";
export var send = "Footer-module--send--2BEZB";
export var sm = "Footer-module--sm--1ncre";
export var footer = "Footer-module--footer--1wQcI";
export var logoWrap = "Footer-module--logoWrap--3fB9k";
export var linksWrap = "Footer-module--linksWrap--gcY5W";