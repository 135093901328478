// extracted by mini-css-extract-plugin
export var mt0 = "SectionTitle-module--mt-0--SuhM8";
export var pt0 = "SectionTitle-module--pt-0--12wOx";
export var mb0 = "SectionTitle-module--mb-0--Zu5BU";
export var pb0 = "SectionTitle-module--pb-0--2TTr2";
export var ml0 = "SectionTitle-module--ml-0--HItEA";
export var pl0 = "SectionTitle-module--pl-0--1fMjQ";
export var mr0 = "SectionTitle-module--mr-0--GRrHz";
export var pr0 = "SectionTitle-module--pr-0--EqC8d";
export var mt5 = "SectionTitle-module--mt-5--2yLzC";
export var pt5 = "SectionTitle-module--pt-5--DS8QF";
export var mb5 = "SectionTitle-module--mb-5--2CNbd";
export var pb5 = "SectionTitle-module--pb-5--22IER";
export var ml5 = "SectionTitle-module--ml-5--2JjSo";
export var pl5 = "SectionTitle-module--pl-5--irPdb";
export var mr5 = "SectionTitle-module--mr-5--PY9mw";
export var pr5 = "SectionTitle-module--pr-5--RQ9gd";
export var mt10 = "SectionTitle-module--mt-10--3oYXX";
export var pt10 = "SectionTitle-module--pt-10--vRUBJ";
export var mb10 = "SectionTitle-module--mb-10--2SnQa";
export var pb10 = "SectionTitle-module--pb-10--21oF-";
export var ml10 = "SectionTitle-module--ml-10--1CkEV";
export var pl10 = "SectionTitle-module--pl-10--1bxni";
export var mr10 = "SectionTitle-module--mr-10--1czpc";
export var pr10 = "SectionTitle-module--pr-10--1YTwB";
export var mt15 = "SectionTitle-module--mt-15--21kYk";
export var pt15 = "SectionTitle-module--pt-15--3pYq_";
export var mb15 = "SectionTitle-module--mb-15--3BN60";
export var pb15 = "SectionTitle-module--pb-15--3pwuQ";
export var ml15 = "SectionTitle-module--ml-15--1imvd";
export var pl15 = "SectionTitle-module--pl-15--1H-I_";
export var mr15 = "SectionTitle-module--mr-15--2b4P1";
export var pr15 = "SectionTitle-module--pr-15--1BGb9";
export var mt20 = "SectionTitle-module--mt-20--q8XzT";
export var pt20 = "SectionTitle-module--pt-20--bJvad";
export var mb20 = "SectionTitle-module--mb-20--3NfRZ";
export var pb20 = "SectionTitle-module--pb-20--2uWmC";
export var ml20 = "SectionTitle-module--ml-20--HYe9t";
export var pl20 = "SectionTitle-module--pl-20--2G8Ju";
export var mr20 = "SectionTitle-module--mr-20--1fbiP";
export var pr20 = "SectionTitle-module--pr-20--3_hoT";
export var mt25 = "SectionTitle-module--mt-25--1dk5E";
export var pt25 = "SectionTitle-module--pt-25--Sbrki";
export var mb25 = "SectionTitle-module--mb-25--15rY7";
export var pb25 = "SectionTitle-module--pb-25--eYPTo";
export var ml25 = "SectionTitle-module--ml-25--32wp2";
export var pl25 = "SectionTitle-module--pl-25--3kVLk";
export var mr25 = "SectionTitle-module--mr-25--1vI9V";
export var pr25 = "SectionTitle-module--pr-25--2g0A0";
export var mt30 = "SectionTitle-module--mt-30--3f9rP";
export var pt30 = "SectionTitle-module--pt-30--1IXNs";
export var mb30 = "SectionTitle-module--mb-30--tUYzP";
export var pb30 = "SectionTitle-module--pb-30--3q32s";
export var ml30 = "SectionTitle-module--ml-30--3uD46";
export var pl30 = "SectionTitle-module--pl-30--1Ak6R";
export var mr30 = "SectionTitle-module--mr-30--2vpij";
export var pr30 = "SectionTitle-module--pr-30--3WdfN";
export var mt35 = "SectionTitle-module--mt-35--2i6u1";
export var pt35 = "SectionTitle-module--pt-35--3aVVW";
export var mb35 = "SectionTitle-module--mb-35--2MrMY";
export var pb35 = "SectionTitle-module--pb-35--R51Qx";
export var ml35 = "SectionTitle-module--ml-35--2yyI3";
export var pl35 = "SectionTitle-module--pl-35--IL-6O";
export var mr35 = "SectionTitle-module--mr-35--20ycl";
export var pr35 = "SectionTitle-module--pr-35--3U4oU";
export var mt40 = "SectionTitle-module--mt-40--2ezfx";
export var pt40 = "SectionTitle-module--pt-40--3I7O3";
export var mb40 = "SectionTitle-module--mb-40--2rM91";
export var pb40 = "SectionTitle-module--pb-40--P8gRM";
export var ml40 = "SectionTitle-module--ml-40--1oyF9";
export var pl40 = "SectionTitle-module--pl-40--1mW-P";
export var mr40 = "SectionTitle-module--mr-40--3lADW";
export var pr40 = "SectionTitle-module--pr-40--1EqAK";
export var mt45 = "SectionTitle-module--mt-45--5vzyg";
export var pt45 = "SectionTitle-module--pt-45--3nGRU";
export var mb45 = "SectionTitle-module--mb-45--QyG5q";
export var pb45 = "SectionTitle-module--pb-45--3bYQ2";
export var ml45 = "SectionTitle-module--ml-45--3Ubxi";
export var pl45 = "SectionTitle-module--pl-45--2-0lx";
export var mr45 = "SectionTitle-module--mr-45--3D_AC";
export var pr45 = "SectionTitle-module--pr-45--1iWr-";
export var mt50 = "SectionTitle-module--mt-50--3onn_";
export var pt50 = "SectionTitle-module--pt-50--2NZKJ";
export var mb50 = "SectionTitle-module--mb-50--3Wqp0";
export var pb50 = "SectionTitle-module--pb-50--1woWu";
export var ml50 = "SectionTitle-module--ml-50--9wAsy";
export var pl50 = "SectionTitle-module--pl-50--2TDtQ";
export var mr50 = "SectionTitle-module--mr-50--1WrOA";
export var pr50 = "SectionTitle-module--pr-50--2ks64";
export var mt75 = "SectionTitle-module--mt-75--2cqLg";
export var pt75 = "SectionTitle-module--pt-75--Srvt1";
export var mb75 = "SectionTitle-module--mb-75--1X1bj";
export var pb75 = "SectionTitle-module--pb-75--2GczR";
export var ml75 = "SectionTitle-module--ml-75--22-RY";
export var pl75 = "SectionTitle-module--pl-75--UO42N";
export var mr75 = "SectionTitle-module--mr-75--3oc7S";
export var pr75 = "SectionTitle-module--pr-75--3G41N";
export var mt100 = "SectionTitle-module--mt-100--2mM9C";
export var pt100 = "SectionTitle-module--pt-100--PxIOs";
export var mb100 = "SectionTitle-module--mb-100--4OCl4";
export var pb100 = "SectionTitle-module--pb-100--1kDaY";
export var ml100 = "SectionTitle-module--ml-100--1iwcd";
export var pl100 = "SectionTitle-module--pl-100--2kebr";
export var mr100 = "SectionTitle-module--mr-100--KZUMg";
export var pr100 = "SectionTitle-module--pr-100--3nbmw";
export var zIndex1 = "SectionTitle-module--z-index-1--25IEz";
export var zIndex2 = "SectionTitle-module--z-index-2--1Tez2";
export var zIndex3 = "SectionTitle-module--z-index-3--2EG-R";
export var zIndex4 = "SectionTitle-module--z-index-4--1uwH7";
export var zIndex5 = "SectionTitle-module--z-index-5--1nNpk";
export var zIndex6 = "SectionTitle-module--z-index-6--3iSfP";
export var zIndex7 = "SectionTitle-module--z-index-7--hPITR";
export var zIndex8 = "SectionTitle-module--z-index-8--3lSeY";
export var zIndex9 = "SectionTitle-module--z-index-9--3cJ3U";
export var zIndex10 = "SectionTitle-module--z-index-10--mTVqm";
export var zIndex20 = "SectionTitle-module--z-index-20--1SBSs";
export var zIndex30 = "SectionTitle-module--z-index-30--1u5lo";
export var zIndex40 = "SectionTitle-module--z-index-40--1Ld-i";
export var zIndex50 = "SectionTitle-module--z-index-50--5bGBZ";
export var zIndex60 = "SectionTitle-module--z-index-60--3Oc7u";
export var zIndex70 = "SectionTitle-module--z-index-70--3oDYR";
export var zIndex80 = "SectionTitle-module--z-index-80--3P_TL";
export var zIndex90 = "SectionTitle-module--z-index-90--1AU3G";
export var zIndex100 = "SectionTitle-module--z-index-100--3MtG3";
export var link = "SectionTitle-module--link--OBAu8";
export var uppercase = "SectionTitle-module--uppercase--2mp2v";
export var more = "SectionTitle-module--more--u0wfw";
export var button = "SectionTitle-module--button--1ltJL";
export var send = "SectionTitle-module--send--1qITg";
export var sm = "SectionTitle-module--sm--1kDjU";
export var sectionTitleWrap = "SectionTitle-module--sectionTitleWrap--3lsUY";
export var sectionTitle = "SectionTitle-module--sectionTitle--3E2Wt";
export var center = "SectionTitle-module--center--1pwaO";
export var left = "SectionTitle-module--left--3MkVu";
export var right = "SectionTitle-module--right--Yh7Ur";
export var transformNone = "SectionTitle-module--transformNone--2CZtx";
export var shadowText = "SectionTitle-module--shadowText--1sUZi";