// extracted by mini-css-extract-plugin
export var mt0 = "Section-module--mt-0--27ybh";
export var pt0 = "Section-module--pt-0--QRhqO";
export var mb0 = "Section-module--mb-0--2Tc9v";
export var pb0 = "Section-module--pb-0--n_GeR";
export var ml0 = "Section-module--ml-0--2Rwq6";
export var pl0 = "Section-module--pl-0--mpLYt";
export var mr0 = "Section-module--mr-0--3ZzgG";
export var pr0 = "Section-module--pr-0--3xJLT";
export var mt5 = "Section-module--mt-5--3KbYC";
export var pt5 = "Section-module--pt-5--rDjDt";
export var mb5 = "Section-module--mb-5--24gJ6";
export var pb5 = "Section-module--pb-5--d8hAS";
export var ml5 = "Section-module--ml-5--20E_p";
export var pl5 = "Section-module--pl-5--2NG2Y";
export var mr5 = "Section-module--mr-5--2mv2R";
export var pr5 = "Section-module--pr-5--1bVcC";
export var mt10 = "Section-module--mt-10--22cyP";
export var pt10 = "Section-module--pt-10--342mN";
export var mb10 = "Section-module--mb-10--3-TAS";
export var pb10 = "Section-module--pb-10--1WaOq";
export var ml10 = "Section-module--ml-10--o-dnf";
export var pl10 = "Section-module--pl-10--2P7XD";
export var mr10 = "Section-module--mr-10--VOu1I";
export var pr10 = "Section-module--pr-10--1Qffv";
export var mt15 = "Section-module--mt-15--2cgs5";
export var pt15 = "Section-module--pt-15--3Th64";
export var mb15 = "Section-module--mb-15--nmglx";
export var pb15 = "Section-module--pb-15--2zT01";
export var ml15 = "Section-module--ml-15--2lJj3";
export var pl15 = "Section-module--pl-15--1nsDU";
export var mr15 = "Section-module--mr-15--3Ib_6";
export var pr15 = "Section-module--pr-15--31Sqz";
export var mt20 = "Section-module--mt-20--2ZcSH";
export var pt20 = "Section-module--pt-20--3YRlC";
export var mb20 = "Section-module--mb-20--3NkEd";
export var pb20 = "Section-module--pb-20--3gazh";
export var ml20 = "Section-module--ml-20--1uxNN";
export var pl20 = "Section-module--pl-20--hz2hK";
export var mr20 = "Section-module--mr-20--2crus";
export var pr20 = "Section-module--pr-20--2VEmt";
export var mt25 = "Section-module--mt-25--3Z7K0";
export var pt25 = "Section-module--pt-25--14cGj";
export var mb25 = "Section-module--mb-25--2hRLp";
export var pb25 = "Section-module--pb-25--3uN-R";
export var ml25 = "Section-module--ml-25--3tsxA";
export var pl25 = "Section-module--pl-25--2LVcj";
export var mr25 = "Section-module--mr-25--1LcI-";
export var pr25 = "Section-module--pr-25--1hENb";
export var mt30 = "Section-module--mt-30--2Eed3";
export var pt30 = "Section-module--pt-30--1Q8Wi";
export var mb30 = "Section-module--mb-30--25u4q";
export var pb30 = "Section-module--pb-30--jnvSb";
export var ml30 = "Section-module--ml-30--ZvtUF";
export var pl30 = "Section-module--pl-30--1P4Zc";
export var mr30 = "Section-module--mr-30--zWrFm";
export var pr30 = "Section-module--pr-30--aSiy0";
export var mt35 = "Section-module--mt-35--1kQk1";
export var pt35 = "Section-module--pt-35--pQPdn";
export var mb35 = "Section-module--mb-35--3RhCl";
export var pb35 = "Section-module--pb-35--YVGjw";
export var ml35 = "Section-module--ml-35--tXmtG";
export var pl35 = "Section-module--pl-35--2wSZi";
export var mr35 = "Section-module--mr-35--6GQcz";
export var pr35 = "Section-module--pr-35--B72LH";
export var mt40 = "Section-module--mt-40--2fFaa";
export var pt40 = "Section-module--pt-40--2lPOr";
export var mb40 = "Section-module--mb-40--1BCyT";
export var pb40 = "Section-module--pb-40--3xZfG";
export var ml40 = "Section-module--ml-40--2jlCj";
export var pl40 = "Section-module--pl-40--1czDI";
export var mr40 = "Section-module--mr-40--3Yfj9";
export var pr40 = "Section-module--pr-40--3k6tv";
export var mt45 = "Section-module--mt-45--2n3Rq";
export var pt45 = "Section-module--pt-45--2gdAL";
export var mb45 = "Section-module--mb-45--1prWz";
export var pb45 = "Section-module--pb-45--WN9kW";
export var ml45 = "Section-module--ml-45--1LG7r";
export var pl45 = "Section-module--pl-45--3Lp-H";
export var mr45 = "Section-module--mr-45--_0irW";
export var pr45 = "Section-module--pr-45--1SnKY";
export var mt50 = "Section-module--mt-50--faY0d";
export var pt50 = "Section-module--pt-50--1H0A1";
export var mb50 = "Section-module--mb-50--38Y7p";
export var pb50 = "Section-module--pb-50--3WCF6";
export var ml50 = "Section-module--ml-50--uZBPp";
export var pl50 = "Section-module--pl-50--1i-Dp";
export var mr50 = "Section-module--mr-50--ewbVa";
export var pr50 = "Section-module--pr-50--1SHUE";
export var mt75 = "Section-module--mt-75--2wMeC";
export var pt75 = "Section-module--pt-75--1GUaG";
export var mb75 = "Section-module--mb-75--2ZLNp";
export var pb75 = "Section-module--pb-75--rJyNs";
export var ml75 = "Section-module--ml-75--eAI4u";
export var pl75 = "Section-module--pl-75--1J52o";
export var mr75 = "Section-module--mr-75--pWTpr";
export var pr75 = "Section-module--pr-75--1T9mp";
export var mt100 = "Section-module--mt-100--3iKsx";
export var pt100 = "Section-module--pt-100--14-P-";
export var mb100 = "Section-module--mb-100--1pGrQ";
export var pb100 = "Section-module--pb-100--U1C4S";
export var ml100 = "Section-module--ml-100--3QvKL";
export var pl100 = "Section-module--pl-100--OE3vU";
export var mr100 = "Section-module--mr-100--10JcJ";
export var pr100 = "Section-module--pr-100--1KKt3";
export var zIndex1 = "Section-module--z-index-1--1A22a";
export var zIndex2 = "Section-module--z-index-2--e_USv";
export var zIndex3 = "Section-module--z-index-3--3oR8H";
export var zIndex4 = "Section-module--z-index-4--2Gx5D";
export var zIndex5 = "Section-module--z-index-5--21xpW";
export var zIndex6 = "Section-module--z-index-6--2Ee46";
export var zIndex7 = "Section-module--z-index-7--FT24U";
export var zIndex8 = "Section-module--z-index-8--2IxSf";
export var zIndex9 = "Section-module--z-index-9--19Zn1";
export var zIndex10 = "Section-module--z-index-10--3Eg8h";
export var zIndex20 = "Section-module--z-index-20--2mKEX";
export var zIndex30 = "Section-module--z-index-30--2jyEL";
export var zIndex40 = "Section-module--z-index-40--3q94s";
export var zIndex50 = "Section-module--z-index-50--2Q5Tg";
export var zIndex60 = "Section-module--z-index-60--9fudI";
export var zIndex70 = "Section-module--z-index-70--3eCzG";
export var zIndex80 = "Section-module--z-index-80--1Wu4E";
export var zIndex90 = "Section-module--z-index-90--x-ptD";
export var zIndex100 = "Section-module--z-index-100--3xt1s";
export var link = "Section-module--link--24L-3";
export var uppercase = "Section-module--uppercase--15GVm";
export var more = "Section-module--more--1i1Y3";
export var button = "Section-module--button--2oBGq";
export var send = "Section-module--send--2IfPr";
export var sm = "Section-module--sm--2Fq28";
export var section = "Section-module--section--fhA4K";