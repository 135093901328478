// extracted by mini-css-extract-plugin
export var mt0 = "Contact-module--mt-0--f8w_A";
export var pt0 = "Contact-module--pt-0--3HfQQ";
export var mb0 = "Contact-module--mb-0--kT8Ju";
export var pb0 = "Contact-module--pb-0--2gcUd";
export var ml0 = "Contact-module--ml-0--3Io2p";
export var pl0 = "Contact-module--pl-0--2S2-T";
export var mr0 = "Contact-module--mr-0--1epRN";
export var pr0 = "Contact-module--pr-0--3rtQS";
export var mt5 = "Contact-module--mt-5--xMvx0";
export var pt5 = "Contact-module--pt-5--3xUtS";
export var mb5 = "Contact-module--mb-5--3ZkG7";
export var pb5 = "Contact-module--pb-5--1ssts";
export var ml5 = "Contact-module--ml-5--3Omji";
export var pl5 = "Contact-module--pl-5--3qVMd";
export var mr5 = "Contact-module--mr-5--QUTUa";
export var pr5 = "Contact-module--pr-5--1Igq4";
export var mt10 = "Contact-module--mt-10--2qZ4_";
export var pt10 = "Contact-module--pt-10--19TtD";
export var mb10 = "Contact-module--mb-10--WPDVP";
export var pb10 = "Contact-module--pb-10--3uI4w";
export var ml10 = "Contact-module--ml-10--1DrhB";
export var pl10 = "Contact-module--pl-10--THsQu";
export var mr10 = "Contact-module--mr-10--2sDgO";
export var pr10 = "Contact-module--pr-10--GQls2";
export var mt15 = "Contact-module--mt-15--2iRE1";
export var pt15 = "Contact-module--pt-15--37VVH";
export var mb15 = "Contact-module--mb-15--tWm1a";
export var pb15 = "Contact-module--pb-15--1WydI";
export var ml15 = "Contact-module--ml-15--3ZI0Q";
export var pl15 = "Contact-module--pl-15--33OLw";
export var mr15 = "Contact-module--mr-15--2bQhq";
export var pr15 = "Contact-module--pr-15--1jHFK";
export var mt20 = "Contact-module--mt-20--1XlNA";
export var pt20 = "Contact-module--pt-20--3JwJU";
export var mb20 = "Contact-module--mb-20--CX8eu";
export var pb20 = "Contact-module--pb-20--3sca5";
export var ml20 = "Contact-module--ml-20--1kVhb";
export var pl20 = "Contact-module--pl-20--3Qd5p";
export var mr20 = "Contact-module--mr-20--egp4j";
export var pr20 = "Contact-module--pr-20--1rIlV";
export var mt25 = "Contact-module--mt-25--CMQGA";
export var pt25 = "Contact-module--pt-25--2dYCO";
export var mb25 = "Contact-module--mb-25--1Gejl";
export var pb25 = "Contact-module--pb-25--1MW1D";
export var ml25 = "Contact-module--ml-25--NWfx4";
export var pl25 = "Contact-module--pl-25--3swFt";
export var mr25 = "Contact-module--mr-25--3SrLn";
export var pr25 = "Contact-module--pr-25--kbsQs";
export var mt30 = "Contact-module--mt-30--tpsP7";
export var pt30 = "Contact-module--pt-30--3qidD";
export var mb30 = "Contact-module--mb-30--2jk8l";
export var pb30 = "Contact-module--pb-30--21xSS";
export var ml30 = "Contact-module--ml-30--2yDoM";
export var pl30 = "Contact-module--pl-30--v7tH9";
export var mr30 = "Contact-module--mr-30--7y35l";
export var pr30 = "Contact-module--pr-30--usLAP";
export var mt35 = "Contact-module--mt-35--2ktVA";
export var pt35 = "Contact-module--pt-35--UzGFZ";
export var mb35 = "Contact-module--mb-35--2GUiT";
export var pb35 = "Contact-module--pb-35--1Iqgc";
export var ml35 = "Contact-module--ml-35--2YXFX";
export var pl35 = "Contact-module--pl-35--1fpZv";
export var mr35 = "Contact-module--mr-35--sxZgr";
export var pr35 = "Contact-module--pr-35--1S3Km";
export var mt40 = "Contact-module--mt-40--3KzMd";
export var pt40 = "Contact-module--pt-40--1yaYD";
export var mb40 = "Contact-module--mb-40--1h2KS";
export var pb40 = "Contact-module--pb-40--Zk-th";
export var ml40 = "Contact-module--ml-40--2_e35";
export var pl40 = "Contact-module--pl-40--2bh7t";
export var mr40 = "Contact-module--mr-40--1VWIU";
export var pr40 = "Contact-module--pr-40--3FTwt";
export var mt45 = "Contact-module--mt-45--2qOZR";
export var pt45 = "Contact-module--pt-45--3tyXe";
export var mb45 = "Contact-module--mb-45--3vsee";
export var pb45 = "Contact-module--pb-45--1VdS8";
export var ml45 = "Contact-module--ml-45--1S5wu";
export var pl45 = "Contact-module--pl-45--34cz8";
export var mr45 = "Contact-module--mr-45--1UXs1";
export var pr45 = "Contact-module--pr-45--20VCa";
export var mt50 = "Contact-module--mt-50--2BPsj";
export var pt50 = "Contact-module--pt-50--2m7Vv";
export var mb50 = "Contact-module--mb-50--3tHnd";
export var pb50 = "Contact-module--pb-50--NqF-_";
export var ml50 = "Contact-module--ml-50--3hj4k";
export var pl50 = "Contact-module--pl-50--3CnFO";
export var mr50 = "Contact-module--mr-50--22i5i";
export var pr50 = "Contact-module--pr-50--C58t1";
export var mt75 = "Contact-module--mt-75--3ur--";
export var pt75 = "Contact-module--pt-75--DCCv8";
export var mb75 = "Contact-module--mb-75--EUZRd";
export var pb75 = "Contact-module--pb-75--2C4Jp";
export var ml75 = "Contact-module--ml-75--1qy27";
export var pl75 = "Contact-module--pl-75--BSQwV";
export var mr75 = "Contact-module--mr-75--kjeh3";
export var pr75 = "Contact-module--pr-75--235M3";
export var mt100 = "Contact-module--mt-100--1tJIX";
export var pt100 = "Contact-module--pt-100--2tu9h";
export var mb100 = "Contact-module--mb-100--1N2NO";
export var pb100 = "Contact-module--pb-100--3Hizt";
export var ml100 = "Contact-module--ml-100--3B1yo";
export var pl100 = "Contact-module--pl-100--bFksD";
export var mr100 = "Contact-module--mr-100--3ULKh";
export var pr100 = "Contact-module--pr-100--34JcD";
export var zIndex1 = "Contact-module--z-index-1--3ocFP";
export var zIndex2 = "Contact-module--z-index-2--23LLd";
export var zIndex3 = "Contact-module--z-index-3--2Z9Kk";
export var zIndex4 = "Contact-module--z-index-4--3RIei";
export var zIndex5 = "Contact-module--z-index-5---4gfA";
export var zIndex6 = "Contact-module--z-index-6--1bHx4";
export var zIndex7 = "Contact-module--z-index-7--3QYKW";
export var zIndex8 = "Contact-module--z-index-8--2BEfV";
export var zIndex9 = "Contact-module--z-index-9--3fu0i";
export var zIndex10 = "Contact-module--z-index-10--2K5ct";
export var zIndex20 = "Contact-module--z-index-20--2SHRs";
export var zIndex30 = "Contact-module--z-index-30--LdBiY";
export var zIndex40 = "Contact-module--z-index-40--3Yiad";
export var zIndex50 = "Contact-module--z-index-50--1nPRz";
export var zIndex60 = "Contact-module--z-index-60--37wbP";
export var zIndex70 = "Contact-module--z-index-70--184Hw";
export var zIndex80 = "Contact-module--z-index-80--2SJuq";
export var zIndex90 = "Contact-module--z-index-90--3Ywfa";
export var zIndex100 = "Contact-module--z-index-100--kMehP";
export var link = "Contact-module--link--3bagS";
export var uppercase = "Contact-module--uppercase--1Nevt";
export var more = "Contact-module--more--2CC3K";
export var button = "Contact-module--button--2ZqdL";
export var send = "Contact-module--send--y1JSe";
export var sm = "Contact-module--sm--3DhOe";
export var contactWrap = "Contact-module--contactWrap--2KSCK";
export var basic = "Contact-module--basic--2GiZp";
export var formWrap = "Contact-module--formWrap--2ndBr";
export var textareaWrap = "Contact-module--textareaWrap--1zaZ_";
export var textarea = "Contact-module--textarea--3PVZc";
export var product = "Contact-module--product--23T-K";
export var inputsWrap = "Contact-module--inputsWrap--3yOUA";
export var afterSendMessage = "Contact-module--afterSendMessage--3Wr4K";
export var contactInfo = "Contact-module--contactInfo--11EOc";
export var email = "Contact-module--email--6QNyu";
export var phone = "Contact-module--phone--1zabH";
export var label = "Contact-module--label--1-TDN";
export var inputWrap = "Contact-module--inputWrap--1S5kC";